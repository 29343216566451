<template>
  <v-app>
    <v-layout justify-center align-center class="bg-white">
      <v-layout class="box-noauth bg-main" />
      <v-layout class="w-main h-wrap-auth pos-rel py-8">
        <h2 class="logo pos-abs" />
        <v-layout justify-center align-center>
          <v-layout
            align-center
            column
            class="form login-frm box-shadow px-13 py-16"
          >
            <label
              class="text-uppercase font-weight-medium text-size-title mt-8"
            >
              {{ $t('enter_code_otp') }}
            </label>
            <label class="mb-10 text-body-2">
              {{ `${$t('enter_code_sent_to_phone')} ${phoneShow}` }}
            </label>
            <label
              :class="
                countDown === 0 ? 'error--text text-body-2' : 'text-body-2'
              "
            >
              {{ messageCountDown }}
              <span v-if="countDown > 0" class="primary--text">
                {{ countDown }}s
              </span>
            </label>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-form @submit.prevent="submit">
                <v-layout column>
                  <v-container cla pa-0 style="width: 334px">
                    <validation-provider
                      v-slot="{ errors }"
                      name="otp"
                      :rules="otpRules"
                    >
                      <v-otp-input
                        v-model="otp"
                        type="password"
                        dense
                        :length="maximumOtp"
                        :error-messages="errors"
                        :disabled="isStateSuppend"
                        @keypress="isNumber($event)"
                        @input="onInputOtp"
                      />
                    </validation-provider>
                  </v-container>
                </v-layout>
                <div>
                  <v-btn
                    block
                    x-large
                    color="primary"
                    type="submit"
                    class="rounded-lg mt-12 mb-2"
                    :disabled="invalid"
                  >
                    <span class="font-weight-medium white--text">
                      {{ $t('continue') }}
                    </span>
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>

            <span
              v-if="countDown === 0"
              class="text-body-2 cursor-pointer"
              @click="onResendOtp"
            >
              {{ $t('resend_otp') }}?
            </span>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :persistent="persistent"
      :icon-src="getIcon"
      :message="message"
    />
  </v-app>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import constants from '@/constants'
import routePaths from '@/router/routePaths'
import { FormUtils } from '@/helpers/formUtils'
import { UserService } from '@/services/userService'
import { StringUtils } from '@/helpers/stringUtils'
import moment from 'moment'
import { DateTimeUtils } from '@/helpers/dateTimeUtils'
import { StorageService } from '@/services/storageService'

export default {
  components: { DialogLoading, DialogNotification },
  data: () => ({
    storeData: null,
    otp: '',
    maximumOtp: constants.maximumOtp,
    otpRules: {
      required: true,
      min: constants.maximumOtp,
      max: constants.maximumOtp
    },
    messageCountDown: '',
    countDown: 0,
    isStateSuppend: false,

    // loading
    showLoading: false,

    // notifications
    showNoti: false,
    persistent: false,
    typeNoti: constants.typeAlert.warning,
    message: ''
  }),
  computed: {
    phoneShow() {
      if (this.storeData.phone) {
        return this.storeData.phone
      }

      return ''
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    }
  },
  created() {
    this.storeData = this.$store.state.user.forgotPassword
    const msg = this.$route.query.message
    if (this.isOverLimit(msg)) {
      this.countDown = 0
      this.messageCountDown = this.$t('verify_code_expired')
      this.showNotiOverLimit(msg)
      this.isStateSuppend = true
    } else {
      this.countDown = this.calculateTimeCountDown()
      if (this.countDown > 0) {
        this.messageCountDown = this.$t('code_expires_later')
        this.countDownTimer()
      } else {
        this.messageCountDown = this.$t('verify_code_expired')
      }
    }
  },
  methods: {
    calculateTimeCountDown() {
      const timestamp = StorageService.getTimeGetOtp()
      const currentDate = moment().format()
      const checkTime = moment(currentDate).diff(moment(timestamp), 'seconds')
      if (checkTime <= 0) return constants.initTimeCountDown

      if (checkTime > 0 && checkTime < constants.initTimeCountDown) {
        return constants.initTimeCountDown - checkTime
      }

      return 0
    },
    async submit() {
      var valid = this.$refs.observer.validate()
      if (valid) {
        this.verifyForgotPassword()
      }
    },
    async verifyForgotPassword() {
      this.showLoading = true
      const pars = this.bindRequestPars()
      const { status, data } = await UserService.forgotPasswordVerify(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok) {
        this.$router.push({
          path: routePaths.FORGOT_PASSWORD_UPDATE,
          query: {
            token: data.token
          }
        })
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
        this.otp = ''
        setTimeout(() => {
          this.toggleDialogNoti()
        }, constants.timeOut)
      }
    },
    bindRequestPars() {
      const rqPars = this.storeData
      rqPars['otp'] = this.otp
      return rqPars
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt)
    },
    onInputOtp(value) {
      if (value.length === constants.maximumOtp) {
        this.invalid = false
      } else {
        this.invalid = true
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.messageCountDown = this.$t('verify_code_expired')
      }
    },
    async onResendOtp() {
      if (this.isStateSuppend) return

      this.showLoading = true
      const pars = {
        phone: StorageService.getUserName()
      }
      const { status, data } = await UserService.otpResend(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok) {
        StorageService.setTimeGetOtp(data.metadata.timestamp)
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: data.payload
        })
        this.messageCountDown = this.$t('code_expires_later')
        this.countDown = this.calculateTimeCountDown()
        this.countDownTimer()
        this.otp = ''
        setTimeout(() => {
          this.toggleDialogNoti()
        }, 1500)
      } else {
        if (this.isOverLimit(data.message)) {
          this.showNotiOverLimit(data.message)
          this.isStateSuppend = true
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }

        this.otp = ''
      }
    },
    isOverLimit(msg) {
      return (
        typeof msg !== constants.undefined &&
        msg !== null &&
        msg.startsWith(constants.startsWithOtpOverLimit)
      )
    },
    showNotiOverLimit(msg) {
      const arrMsg = msg.split(',')
      const msgShow = `${this.$t('request_send_otp_too')} ${
        arrMsg[0]
      } ${this.$t('times')}. ${this.$t(
        'please_try_again_later'
      )} ${DateTimeUtils.formatDateTime(arrMsg[1], constants.formatDateTimeHm)}`

      this.toggleDialogNoti({ state: true, msg: msgShow })

      const expDate = arrMsg[1]
      const currentDate = moment().format()
      const checkTime = moment(currentDate).diff(moment(expDate), 'seconds')
      if (checkTime < 0) {
        const timeOut = Math.abs(checkTime) * 1000
        setTimeout(() => {
          this.toggleDialogNoti()
          this.isStateSuppend = false
        }, timeOut)
      }
    },
    toggleDialogNoti(
      pars = {
        state: false,
        persistent: false,
        type: constants.typeAlert.warning,
        msg: ''
      }
    ) {
      this.showNoti = pars.state
      this.persistent = pars.persistent
      this.typeNoti = pars.type
      this.message = pars.msg
    }
  }
}
</script>
<style src="@/styles/form.scss" lang="scss" />
